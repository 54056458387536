
	import { Component, Vue } from "vue-property-decorator";
	import request from "@/request";
	import FloorSelector from "@/components/floorSelector/floorSelector.vue";

	@Component({
		components: {
			FloorSelector
		}
	})
		export default class DeviceRoot extends Vue {
		floors:{ name:string, id:string }[] = [];

		mounted () {
			request({
				url: "/space/floor/",
				params: {
					facility: 1
				}
			}).then(({ data }) => {
				this.floors = (data as { name:string, id:string }[]).reverse().map((t) => ({
					name: t.name,
					id: t.id
				}));
			});
		}
	}

